<template>
<div class="flex flex-column flex-auto">
    <div class="p-5">
        <div class="grid">
            <!-- Details card -->
            <div class="col-12">
                <div class="surface-card shadow-2 border-round p-3 mb-3">
                    <div class="grid">
                        <div class="col-2">
                            <p><b>Domains submitted today:</b></p>
                        </div>
                        <div class="col-10">
                            <p>{{ this.userStats.alive }}</p>
                        </div>
                    </div>

                    <div class="grid">
                        <div class="col-2">
                            <p><b>Marked as dead today:</b></p>
                        </div>
                        <div class="col-10">
                            <p>{{ this.userStats.dead }}</p>
                        </div>
                    </div>

                </div>
            </div>

            <!-- Preselect card -->
            <div class="col-12">
                <div class="surface-card shadow-2 border-round p-3 mb-3">
                    <p><b>Get only this type:</b></p>
                    <SelectButton v-model="preSelectType" :options="preSelectTypes" class="w-full"/>
                </div>
            </div>

            <!-- Details card -->
            <div class="col-12">
                <div class="surface-card shadow-2 border-round p-3 mb-3">
                    <div>
                        <div class="grid">
                            <div class="col-2">
                                <p><b>Original Domain:</b></p>
                            </div>
                            <div class="col-10">
                                <p>{{ this.originalDomain }}</p>
                            </div>
                        </div>

                        <div class="grid">
                            <div class="col-2">
                                <p><b>Original User:</b></p>
                            </div>
                            <div class="col-10">
                                <p>{{ this.originalUser }}</p>
                            </div>
                        </div>

                        <div class="grid">
                            <div class="col-2">
                                <p><b>Search Params:</b></p>
                            </div>
                            <div class="col-10">
                                <p>{{ this.searchParams }}</p>
                            </div>
                        </div>

                        <div class="grid">
                            <div class="col-2">
                                <p><b>Search Delims:</b></p>
                            </div>
                            <div class="col-10">
                                <p>{{ this.searchDelims }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Input card -->
            <div class="col-12">
                <div class="surface-card shadow-2 border-round p-3 mb-3">
                    <Button class="w-full" label="Open Page" @click="openNewTab"/>
                    <InputText class="w-full mt-3 font-bold" v-model="enteredUrl"
                               placeholder="Enter search page url" @paste="onPaste"/>

                    <SelectButton v-model="domainType" :options="domainTypes" class="w-full mt-3"/>

                    <div class="grid">
                        <div class="col-4">
                            <Button class="w-full mt-3" label="Skip" @click="skip"/>
                        </div>
                        <div class="col-4">
                            <Button class="w-full mt-3" label="Dead Domain" @click="deadDomain" :disabled="!loaded"/>
                        </div>
                        <div class="col-4">
                            <Button class="w-full mt-3" label="Submit" @click="submit" :disabled="enteredUrl.length === 0"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: "PreinjectIngest",

    data: function() {
        return {
            loaded: false,
            originalDomain: "loading",
            enteredUrl: "",
            searchParams: "unknown",
            searchDelims: "unknown",
            domainType: "unknown",
            preSelectType: "any",

            originalUser: "unknown",

            exampleMovieName: "the gray man",
            domainTypes: ['movie', 'book', 'product', 'content_creator', 'torrent', 'unknown'],
            preSelectTypes: ['movie', 'book', "any"],

            userStats: {
                alive: 0,
                dead: 0,
            }
        }
    },

    mounted() {
        this.refresh();
    },

    methods: {
        reset() {
            this.domainType = "unknown";
            this.enteredUrl = "";
            this.searchParams = "unknown";
            this.searchDelims = "unknown";
        },

        refresh() {
            this.getNewDomain();
            this.getStats();
        },

        getNewDomain() {
            console.debug('Getting new domain for preinject')
            this.reset();

            this.$factory.datareview.getNewDomainForPreinject(this.preSelectType)
                .then((response) => {
                    console.debug(response);
                    this.originalDomain = response.domain;
                    this.originalUser = response.user;
                    this.domainType = response.type;

                    this.loaded = true;
                })
                .catch((error) => {
                    this.$emitter.emit('bad-toast', 'Error getting new domain for preinject')
                    console.log(error);
                })
        },

        getStats() {
            console.debug('Getting stats for preinject')
            this.$factory.datareview.getStatsForUserAddedDomains()
                .then((response) => {
                    console.debug(response);
                    if (response.alive === null) {
                        this.userStats.alive = 0;
                        this.userStats.dead = 0;
                    } else {
                        this.userStats.alive = response.alive;
                        this.userStats.dead = response.dead;
                    }
                })
                .catch((error) => {
                    this.$emitter.emit('bad-toast', 'Error getting stats for preinject')
                    console.log(error);
                })
        },

        openNewTab() {
            // Open the page in a new tab
            navigator.clipboard.writeText(this.exampleMovieName)
                .then(() => {
                    window.open('https://' + this.originalDomain, '_blank');
                })
                .catch((error) => {
                    this.$emitter.emit('bad-toast', 'Error opening new tab')
                    console.log(error);
                })
        },

        skip() {
            console.debug('Skipping domain')
            this.refresh();
        },

        deadDomain() {
            console.debug('Marking domain as dead')
            let domainData = {
                domain: this.originalDomain,
                type: this.domainType,
                username: this.originalUser
            }

            this.$factory.datareview.markDomainAsDead(domainData)
                .then((response) => {
                    console.debug(response);
                    this.refresh();
                })
                .catch((error) => {
                    this.$emitter.emit('bad-toast', 'Error marking domain as dead')
                    console.log(error);
                })
        },

        submit() {
            console.debug('Submitting domain')
            let domainData = {
                domain: this.originalDomain,
                type: this.domainType,
                username: this.originalUser,
                searchparams: this.searchParams,
                searchdelims: this.searchDelims
            }

            this.$factory.datareview.submitDomain(domainData)
                .then((response) => {
                    console.debug(response);
                    this.refresh();
                })
                .catch((error) => {
                    this.$emitter.emit('bad-toast', 'Error submitting domain')
                    console.log(error);
                })
        },

        onPaste(event) {
            event.preventDefault();
            this.enteredUrl = event.clipboardData.getData('text').toLowerCase();
        },
    },

    watch: {
        enteredUrl: function() {
            // Get the search params and delims
            let paramRegex = /(?<searchTerm>the.{0,3}gray.{0,3}man)/i;
            if (this.enteredUrl.match(paramRegex) === null) return;
            this.searchParams = this.enteredUrl.replace(this.enteredUrl.match(paramRegex).groups.searchTerm, '{}')

            let delimRegex = /the(?<delim>.{0,3})gray.{0,3}man/i;
            if (this.enteredUrl.match(delimRegex) === null) return;
            this.searchDelims = this.enteredUrl.match(delimRegex).groups.delim;
        },

        preSelectType: function() {
            this.refresh();
        }
    }
}
</script>

<style scoped>

</style>
